import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./modalGallery.style.scss";

const ModalGallery = ({
  images,
  indexSlideTo,
  openedModal,
  toggleOpenModal,
  updateIndex,
}) => {
  return (
    <div className={`modal ${openedModal && "is-active"}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="image">
          {images.map((image, ind) => (
            <GatsbyImage
              key={ind}
              className={`carousel-item ${ind !== indexSlideTo && "is-hidden"}`}
              image={getImage(image.imageFile)}
              alt={image.imageFile.name}
            />
          ))}
        </div>
        <div className="buttons has-addons is-centered">
          <button
            className="button is-white is-outlined modal-prev has-text-primary"
            onClick={() =>
              updateIndex(indexSlideTo ? indexSlideTo - 1 : images.length - 1)
            }
          >
            &#9664;
          </button>
          <button
            className="button is-white is-outlined modal-next has-text-primary"
            onClick={() =>
              updateIndex(
                indexSlideTo === images.length - 1 ? 0 : indexSlideTo + 1
              )
            }
          >
            &#9654;
          </button>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => toggleOpenModal(false)}
      />
    </div>
  );
};

export default ModalGallery;
