import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import slugify from "slugify";
import Layout from "../layout";
import Seo from "../layout/seo";
import Gallery from "../components/gallery";
import "../styles/recetteTemplate.scss";

const RecetteTemplate = ({ data }) => {
  const {
    childMarkdownRemark: { frontmatter, html },
    raw: { imageFile },
  } = data.notion;
  return (
    <Layout>
      <Seo />
      <div className="section container" data-component="recetteTemplate">
        {imageFile ? (
          <GatsbyImage
            className="image-title"
            image={getImage(imageFile)}
            alt="cover"
            style={{ width: "100%" }}
          />
        ) : (
          <StaticImage
            className="image-title"
            src="../images/main.jpeg"
            formats={["auto", "webp", "avif"]}
            placeholder="TRACED_SVG"
            layout="constrained"
            alt="cover"
            width={1024}
            style={{ width: "100%" }}
          />
        )}
        <div className="container">
          <h2 className="title is-size-1 has-text-dark has-text-centered">
            {frontmatter.title}
          </h2>
        </div>
        <h3 className="subtitle is-size-5 has-text-weight-light">
          {frontmatter.ajoutLe}
        </h3>
        <div className="categories tags are-medium">
          {frontmatter.categories.map((categorie) => (
            <Link
              to={`/${slugify(categorie.name, {
                strict: true,
                lower: true,
              })}`}
              className="tag is-primary is-inverted has-text-white"
              key={categorie.id}
            >
              {categorie.name}
            </Link>
          ))}
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
        {frontmatter.Image && frontmatter.Image !== [] && (
          <Gallery images={frontmatter.Image} />
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($title: String!) {
    notion(title: { eq: $title }) {
      childMarkdownRemark {
        id
        frontmatter {
          Image {
            imageFile {
              id
              name
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: TRACED_SVG
                  layout: CONSTRAINED
                  width: 1024
                )
              }
            }
          }
          title
          ajoutLe: Ajout_e_le(formatString: "DD MMMYY", locale: "fr")
          categories: Cat_gories {
            name
            id
          }
        }
        html
      }
      raw {
        imageFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              placeholder: TRACED_SVG
              layout: CONSTRAINED
              width: 1024
            )
          }
        }
      }
    }
  }
`;

export default RecetteTemplate;
