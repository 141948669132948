import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./gallery.style.scss";
import ModalGallery from "../modalGallery/ModalGallery.component";

const Gallery = ({ images }) => {
  const [openedModal, toggleOpenModal] = useState(false);
  const [indexSlideTo, updateIndex] = useState(0);

  useEffect(() => {
    openedModal
      ? document.querySelector("html").classList.add("is-clipped")
      : document.querySelector("html").classList.remove("is-clipped");
  }, [openedModal]);

  return (
    <div className="gallery" id="gallery">
      {images.map((image, index) => {
        const { id, name } = image.imageFile;
        return (
          <GatsbyImage
            data-index={index}
            key={id}
            alt={name}
            image={getImage(image.imageFile)}
            onClick={() => {
              updateIndex(index);
              toggleOpenModal(true);
            }}
          />
        );
      })}
      <ModalGallery
        images={images}
        indexSlideTo={indexSlideTo}
        openedModal={openedModal}
        toggleOpenModal={toggleOpenModal}
        updateIndex={updateIndex}
      />
    </div>
  );
};

export default Gallery;
